.App {
  text-align: center;
  background: #F5F5F5;
  min-height: 740px;
  min-width: 800px;
}

button {
  font-size: 15px;
  margin-left: 10px;
  margin-right: 10px;
  text-decoration: none;
  padding: 10px;
  border-radius: 20px;
}

button:focus {
  outline: none;
}

input:focus {
  outline: none;
}

button:hover {
  cursor: pointer;
}

.primary-button {
  background-color: #364E6B;
  color: white;
  border: 1px solid #364E6B;
  display: inline-flex;
  align-items: center;
}

.primary-button:hover {
  background-color: white;
  color: #364E6B;
  border: 1px solid #364E6B;
}

.secondary-button {
  background-color: #828B92;
  color: white;
  border: 1px solid #828B92;
  display: inline-flex;
  align-items: center;
}

.secondary-button:hover {
  background-color: white;
  color: #828B92;
  border: 1px solid white;
}

.icon-button {
  border: none;
  cursor: pointer;
}

.icon{
  padding: 15px;
  height: 40px;
  width: 40px;
}
